<template>
    <div class="home-page">
        <div class="user-info">
            <p class="item-left">
                <b>{{ $store.getters.getUserData.name }}，你好！</b>
            </p>
            <p class="item-right">
                <van-icon name="bell" />
                <b>{{ alarmCount }}条</b>
            </p>
        </div>
        <div class="data-box">
            <router-link class="box-item" to="/client/goods/unreceived">
                <p class="p1">{{ receiveCount }}件</p>
                <p class="p2">待收货物品</p>
            </router-link>
            <router-link class="box-item" to="/client/goods/uninstalled">
                <p class="p1">{{ installCount }}件</p>
                <p class="p2">待安装物品</p>
            </router-link>
        </div>
        <div class="data-list">
            <p class="list-title">待收货列表</p>
            <div class="list-container" v-if="receiveList && receiveList.length > 0">
                <div v-for="(item, index) in receiveList" class="list-item">
                    <p class="p1">{{ item.order.order_sn }}</p>
                    <p class="p2">数量：x {{ item.order.number }}</p>
                    <p class="p3" @click="toDetail(`/client/goods/receive_detail/${item.id}`)">去处理</p>
                </div>
            </div>
            <van-empty v-else description="暂无数据" />
        </div>
        <div class="data-list">
            <p class="list-title">待安装列表</p>
            <div class="list-container" v-if="installList && installList.length > 0">
                <div v-for="(item, index) in installList" class="list-item">
                    <p class="p1">{{ item.order.order_sn }}</p>
                    <p class="p2">数量：x {{ item.order.number }}</p>
                    <p class="p3" @click="toDetail(`/client/goods/uninstalled`)">去处理</p>
                </div>
            </div>
            <van-empty v-else description="暂无数据" />
        </div>
    </div>
</template>

<script setup>
import { GET_GOODS_INSTALL_LIST, GET_GOODS_RECEIVE_LIST } from "@/api/goods";
import { onMounted, ref } from "vue";
import { GET_ALARM_LIST } from "@/api/alarm";
import usePublic from "@/hooks/usePublic";

let { $router } = usePublic();

onMounted(() => {
    getDataList();
});

// 获取列表数据
let receiveList = ref([]);
let installList = ref([]);
let receiveCount = ref(0);
let installCount = ref(0);
let alarmCount = ref(0);

let getDataList = () => {
    GET_GOODS_RECEIVE_LIST({
        type: "stay",
        page: 1,
        limit: 5
    }).then((res) => {
        receiveList.value = res.data.data.list;
        receiveCount.value = res.data.data.mate.total;
        console.log(res.data.data);
    });
    GET_GOODS_INSTALL_LIST({
        status: 0,
        page: 1,
        limit: 5
    }).then((res) => {
        installCount.value = res.data.data.mate.total;
        installList.value = res.data.data.list;
        console.log(res.data.data);
    });
    GET_ALARM_LIST({
        status: 0
    }).then((res) => {
        alarmCount.value = res.data.data.mate.total;
    });
};

// 前往详情
let toDetail = (path) => {
    $router.push({
        path: path
    });
};
</script>

<style lang="scss" scoped>
.home-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;

    .user-info {
        display: flex;
        align-items: center;
        padding: 0 20px;
        background: #ffffff;

        p {
            line-height: 64px;

            b {
                display: block;
                font-size: 16px;
            }
        }

        .item-right {
            display: flex;
            align-items: center;
            margin-left: auto;

            .van-icon {
                display: block;
                margin-right: 5px;
                font-size: 20px;
                color: #f54747;
            }
        }
    }

    .data-box {
        display: flex;
        align-items: center;
        width: 345px;
        margin: 20px auto 0 auto;

        .box-item {
            display: block;
            width: 163px;
            padding: 15px 0;
            text-align: center;
            border-radius: 5px;

            &:first-child {
                background: #df5f0b;
            }

            &:last-child {
                margin-left: auto;
                background: #ff8d1a;
            }

            .p1 {
                font-size: 16px;
                font-weight: bold;
                color: #ffffff;
            }

            .p2 {
                margin-top: 10px;
                font-size: 12px;
                color: #ffffff;
            }
        }
    }

    .data-list {
        width: 345px;
        margin: 20px auto 0 auto;

        .list-title {
            line-height: 20px;
            padding-left: 10px;
            font-size: 14px;
            font-weight: bold;
            color: #000000;
            border-left: 5px solid #ff8d1a;
        }

        .list-container {
            width: 100%;
            margin: 20px 0 0 0;
            padding: 0 10px;
            background: #ffffff;
            border-radius: 10px;

            .list-item {
                display: flex;
                align-items: center;
                padding: 15px 0;
                border-bottom: 1px dashed #eeeeee;

                &:last-child {
                    border-bottom: none;
                }

                p {
                    font-size: 12px;
                }

                .p1 {
                    width: 180px;
                    color: #000000;
                }

                .p2 {
                    color: #999999;
                }

                .p3 {
                    margin-left: auto;
                    color: #df5f0b;
                }
            }
        }
    }
}
</style>
